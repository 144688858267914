body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  max-height: 100%;
}
.wish-tags-all {
  display: flex;
  gap: 10px;
}
.wish-tags-all div {
  padding: 4px 4px;
  display: block;
  border-radius: 4px;
  font-size: 12px;
}
@font-face {
  font-family: "poppins-light";
  src: url("../src/assets/fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "bahnschrift-regular";
  src: url("../src/assets/fonts/bahnschrift-regular.ttf");
}

.poppins-light {
  font-family: "poppins-light";
}

.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-cell:focus {
  outline: none !important;
}
.single-img {
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.deceased-images {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
body {
  font-family: "bahnschrift-regular";
}
.font-10 {
  font-size: 10px;
}

.bahnschrift-regular {
  font-family: "bahnschrift-regular";
}

.login-main-div {
  height: 100vh;
  position: relative !important;
}

.login-con {
  align-items: center;
  height: 100vh;
  width: 600px;
  margin: 0 auto !important;
}

.login-form input {
  border-radius: 0px;
  font-family: "poppins-light";
  font-size: 12px;
}

.btn-login {
  background: linear-gradient(to right, #127224, #15fefe);
  border-radius: 0px;
  color: #fff;
  padding: 10px 60px;
}

/* top-navbar */

/* sidebar-dashboard */

.sidebar-dashboard {
  height: auto !important;
  min-height: 100vh;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
  background-color: #02275e;
}

.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: transparent;
}

.sidebar-menu-design:hover {
  background-color: #127224;
}

.avatar-image-box-dropdown {
  display: flex;
  align-items: center;
}

.avatar-image {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  object-fit: cover;
}

.user-name {
  margin-left: 10px;
}

.my-dataTable {
  border: 1px solid #000;
}

.user-membership {
  text-decoration: none;
  color: #00d9fa;
}

.user-membership:hover {
  border-bottom: #00d9fa 1px solid;
  color: #00d9fa;
}

/* main layout */
.video-player-custom {
  width: 100% !important;
}

.about-us-image {
  height: 250px !important;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.main-layout {
  display: flex !important;
}
.image-detail-add {
  height: 200px !important;
}
.main-content {
  /* width: 100%;
  overflow: hidden; */
}

.btn-show-sidebar {
  background-color: #02275e;
  position: fixed;
  bottom: 20px;
  left: 20px;
  border-radius: 5px;
  border: none;
  display: none;
}

.Close-btn-sidebar {
  position: absolute;
  z-index: 99999;
  left: 240px;
  background-color: transparent;
  border: none;
}
.btn-no-pad {
  padding: 1px 7px !important;
}
.react-tel-input .form-control {
  width: 100% !important;
  height: 40px !important;
}
.close-icon {
  color: #adadad;
}
.new-order {
  background-color: red;
  width: 8px;
  height: 8px;
  border-radius: 25px;
  position: relative;
  top: 5px;
  left: 5px;
}
.open-sidebar-icon {
  color: #adadad;
}
.a-none-tag {
  text-decoration: none;
}
.table-main-header {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.card-header-customs {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.no-btn-structure {
  background-color: transparent;
  border: none;
  font-size: 18px;
}

tr,
th,
td {
  font-size: 14px;
  vertical-align: middle;
}

.switch {
  width: 100px !important;
}

.pagination-custom {
  list-style-type: none;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.pagination-custom a {
  text-decoration: none;
  color: #303030;
}

.w-80-px {
  width: 100px;
}

.avatar-custom {
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  min-width: 50px;
}

.font-18 {
  font-size: 18px;
}

.pagination-custom li {
  padding: 2px 10px;
  border: 1px solid #02275e;
}

.delete-card {
  width: 250px;
}

.btn-delete {
  padding: 2px 10px;
  border: none;
}

.delete-div {
  display: flex;
}

.modal {
  font-size: 12px;
}

.show-hide-btn {
  position: absolute;
  top: 25px;
  right: 5px;
}

.no-image-custom {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 30px;
}

.user-img-table {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50px;
}

.user-details-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50px;
  border: 2px solid #02275e;
  position: relative;
  bottom: 40px;
  left: -1px;
}

.user-details {
  margin-top: -25px !important;
}

.show-992 {
  display: none;
}

.services-para {
  display: inline-block !important;
  width: 100px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-size: 14px;
  margin-bottom: -6px;
  border-radius: 10px;
}

.inner-slide {
  height: 300px;
  width: 100%;
}

.inner-slide img {
  height: 300px;
  width: 100%;
  object-fit: cover !important;
  object-position: center;
}

.inner-slide video {
  height: 300px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.close-btn {
  background: #02275e;
  width: 20px;
  height: 20px;
  padding: 9px;
  position: absolute;
  right: 0;
  z-index: 99;
  border: 0px;
  right: 4px;
  top: 4px;
}

.close-icon {
  color: #fff !important;
  position: relative;
  top: -10px;
  left: -5px;
}

.description-tooltip .tooltip-inner {
  max-width: 250px;
  position: relative;
  padding: 12px 10px;
  font-size: 12px;
  background-color: #127224;
}

.custom-modal-header .btn-close {
  background-image: url("assets/images/close-btn.png") !important;
}

.error-container {
  position: relative;
  height: calc(100vh - 3rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-404 {
  width: 280px;
}

.sidebar-menu-design-custom.active {
  background-color: #127224;
}

.cat-icon {
  font-size: 25px;
}

.toast-updated {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.global-loader {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  z-index: 9999;
}

.w-300 {
  max-width: 300px;
}

.global-loader div {
  justify-content: center;
}
.current-mood {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.current-mood img {
  width: 100px;
  height: 100px;
}

.global-loader-inner {
  justify-content: center;
}

.pagination-custom .selected {
  background-color: #6de8dc;
  color: #fff !important;
}

.card-inner {
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 5px;
  padding: 25px 25px;
  margin-bottom: 25px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: linear-gradient(
    245deg,
    rgb(109 232 220) 40px,
    rgb(25 25 25) 40px
  );
}
.user-img {
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 50px;
  object-fit: cover;
  padding: 2px;
}

.font-12 {
  font-size: 12px;
}
.current-mood-item {
  display: flex;
  justify-content: space-between;
}
.current-mood-item img {
  width: 50px;
  height: 50px;
}

/* Users Search */

.users-search {
  border-radius: 0px !important;
  width: 320px !important;
}

.user-search {
  display: flex;
}

.img-td {
  width: 80px;
}

/* Users Search */

@media (max-width: 992px) {
  .btn-show-sidebar {
    display: block;
  }

  .show-992 {
    display: block;
  }
}

@media (max-width: 600px) {
  .login-con-1 {
    width: 100%;
  }
}

.pro-item-content button:hover {
  background-color: #127224 !important;
}
.top-latest-users-table {
  padding: 20px 20px;
}
.symbol {
  flex-shrink: 0;
  position: relative;
  border-radius: 0.475rem;
  display: flex;
  column-gap: 10px;
}
.symbol.symbol-45px > img {
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  display: inline-block;
  border-radius: 0.475rem;
  border: 1px dashed #02275e;
}

.h-6px {
  height: 6px !important;
}
.top-latest-users-table tr {
  border: 0px !important;
  border-bottom: 1px dashed #e1e3ea !important;
  border-top: 1px dashed #e1e3ea !important;
}
.top-latest-users-table td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: transparent;
  border: 0px !important;
  border-top: 1px dashed #e1e3ea !important;
  border-bottom: 1px dashed #e1e3ea !important;
}
.top-latest-users-table h4 {
  margin-bottom: 5px;
}
.top-latest-users-table h6 {
  color: #a1a5b7;
  font-weight: 400;
  font-size: 14px;
}
.ps-active a {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.MuiDataGrid-columnHeader--moving {
  background-color: transparent !important;
}
.MuiDataGrid-row.MuiTableRow-root:hover {
  background-color: inherit !important;
  pointer-events: none !important;
}
.MuiDataGrid-sortIcon {
  display: none !important;
}

.sidebar-dashboard li,
a {
  color: #191919;
}
.sidebar-dashboard .ps-active {
  background-color: #191919;
  color: #fff;
}

.sidebar-dashboard .ps-menu-button:hover {
  background-color: #191919 !important;
  color: #fff;
}
.sidebar-dashboard-logo {
  text-align: right;
  margin-top: 8px;
  margin-bottom: 8px;
}
.sidebar-dashboard-logo button {
  color: #191919;
  background-color: transparent;
  border: 0;
}
.chart-card {
  padding: 10px 10px;
}

.post-color {
  display: inline-block;
  border: 1px dashed #000;
  border-radius: 4px;
  height: 45px;
  width: 80px;
  text-align: center;
  line-height: 45px;
}
