.bg-gradient-danger .modal-content {
  background: linear-gradient(87deg, #f5365c, #f56036) !important;
}

.bg-gradient-danger-info .modal-content {
  background: linear-gradient(87deg, #44466c, #252862) !important;
}
.bg-gradient-custom .modal-header,
.bg-gradient-custom .modal-footer,
.bg-gradient-custom .modal-body {
  border: 0px !important;
}

.delete-model-icon {
  font-size: 40px !important;
}
