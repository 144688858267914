.user-profile {
  display: flex;
  gap: 20px;
}
.user-profile-main {
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  margin-top: 20px;
}
.user-image-box img {
  width: 160px;
  height: 160px;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
  border: 1px dashed #e1e3ea;
}

.info-icon p {
  margin-bottom: 5px;
}
.font-icon-12 {
  font-size: 12px;
}
.info-icon-app {
  border: 1px dashed #e1e3ea;
  border-radius: 4px;
  padding: 10px 10px;
}
.user-info-app {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.info-icon-app p {
  margin-bottom: 0px;
}
.info-icon-app h5 {
  display: flex;
  align-items: center;
  gap: 5px;
}
.user-profile-nav-tabs .nav-tabs {
  border-bottom: 0px solid #dee2e6;
}
.user-profile-nav-tabs .nav-link.active {
  background-color: #6de8dc;
  border: 1px solid #191919;
  color: #191919;
}
.user-profile-nav-tabs .nav-link {
  border: 1px solid #6de8dc;
  color: #000;
  margin-right: 10px;
}

.user-info-box-personal h3 {
  display: flex;
  align-items: center;
  gap: 5px;
}

.font-icon-14 {
  font-size: 14px;
}
.user-detail-info-table tr {
  height: 50px;
}
.user-info-heading {
  color: #fff;
  padding: 11px 10px;
  margin-bottom: 1px;
}
